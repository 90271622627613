
import { defineComponent, onMounted, ref } from 'vue'
import { Toaster } from '@/common/Toaster'
import router from '@/router'
import { useRoute } from 'vue-router'
import { storeProject, StoreActionsProject } from '@/store/project'
import { __ } from '@/locales'

export default defineComponent({
  name: `ViewInvoice`,
  components: {},
  props: {},
  emits: [],
  setup() {
    const loading = ref(false)
    const report = ref('')
    const route = useRoute()

    onMounted(async () => {
      const id = +route.params.id
      const name = route.params.name

      loading.value = true

      const res = await storeProject.dispatch(StoreActionsProject.GET_INVOICE, {
        projectId: id,
        name: name,
      })
      loading.value = false
      report.value = res.data
    })

    const addInvoice = async (event: CustomEvent) => {
      const res = await storeProject.dispatch(StoreActionsProject.ADD_INVOICE, {
        base64: event.detail.base64,
        file: { name: event.detail.file.name },
        projectId: route.params.id,
      })
      Toaster.toast(res)
      if (res.success) {
        router.push(`${event.detail.file.name}`)
      }
    }

    const deleteInvoice = async () => {
      if (confirm(__('askDeleteInvoice'))) {
        const res = await storeProject.dispatch(
          StoreActionsProject.DELETE_INVOICE,
          {
            name: route.params.name,
            projectId: route.params.id,
          },
        )
        Toaster.toast(res)
        if (res.success) {
          router.back()
        }
      }
    }

    return { report, addInvoice, deleteInvoice, loading }
  },
})
